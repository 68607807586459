<template>
    <div class="deduceDetails">
      <!--头部banner-->
      <div class="hd_head">
        <div class="hd_head_fanhui" :style="colorState?'background:white;':''">
          <van-icon name="arrow-left" size="22" color="#141212" @click="$router.go(-1)" />
        </div>
        <van-swipe class="my-swipe" :show-indicators="false" :autoplay="3000" indicator-color="white">
          <van-swipe-item v-for="(item, index) in imgs" :key="index">
              <van-image :src="item" class="recr_swiper_item_img">
                  <template v-slot:error>加载失败</template>
              </van-image>
          </van-swipe-item>
      </van-swipe>
        <div class="td_head_au">
          <div class="td_head_au_left td_head_color" v-if="videos && videos.length > 0">视频({{videos ? videos.length : 0}})</div>
          <div class="td_head_au_right">图片({{imgs ? imgs.length : 0}})</div>
        </div>
      </div>
      <div class="td_title">
        <div class="td_title_head">
          <div class="td_title_head_left">
            <span>{{scenic.name}}</span>
          </div>
          <div class="td_title_head_right">
            <span v-if="scenic.grade">{{scenic.grade}}分</span>
          </div>
        </div>
        <div class="seller_info_item">
          <div class="seller_info_item_content">电话：{{scenic.telephone}}</div>
          <div class="seller_info_item_opt phone" v-if="scenic.longitude && scenic.latitude">
              <a :href="'tel:'+ scenic.telephone">拨打</a>
          </div>
        </div>
        <div class="seller_info_item" style="border: none">
          <div class="seller_info_item_name seller_address">地址：</div>
          <div class="seller_info_item_content text_over">{{scenic.address}}</div>
          <div class="seller_info_item_opt navigation" v-if="scenic.longitude && scenic.latitude">
              <span @click="goMap()">导航</span>
          </div>
        </div>
        <div class="td-brd"></div>
        <div class="td_title_nav">
          <span>演艺介绍</span>
        </div>

        <div class="td_title_cont" v-html="scenic.depiction">
        </div>
        <div class="td_title_cli" v-if="false">
          <span>展开</span>
        </div>
      </div>
      <!--分割线-->
      <div class="td-brd"></div>
      <div class="re_title">
        套餐详情
      </div>
      <div class="td_tiket">
        <div class="td_tiket_list" v-for="(item, index) in tickets" :key="index">
          <div class="td_tiket_list_head">
            <span>{{item.name}}</span>
            <span>￥{{item.sellPrice ? item.sellPrice/100 : 0}}</span>
          </div>
          <div class="td_tiket_list_bot">
            <span>{{item.tags}}</span>
            <span>原价 ￥{{item.shopPrice ? item.shopPrice/100 : 0}}</span>
          </div>
        </div>
      </div>
      <!-- 评论 -->
      <SHComment
        @praise="praiseOrCollect"
        @collect="praiseOrCollect"
        :isPraise="scenic.isPraise"
        :isCollect="scenic.isCollect"
        :bizId="scenicKey"
        :bizType="bizType"
        :star="scenic.star"
        :collectNum="scenic.collect"
        :praiseNum="scenic.praise"
      ></SHComment>
    </div>
</template>

<script src="./deduceDetails.js"></script>

<style scoped src="./deduceDetails.css"></style>
